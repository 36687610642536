import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "Portfolio", // e.g: 'Name | Developer'
  lang: "en", // e.g: en, es, fr, jp
  description: "Welcome to my website", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  greetings: [
    {
      hello: "my name is",
      name: "Daniel",
      lang: "English",
    },
    {
      hello: "меня зовут",
      name: "Даниэль",
      lang: "Pусский",
    },
    {
      hello: "といいます",
      name: "ダニエル",
      lang: "日本語",
    },
  ],
  networks: [
    {
      id: nanoid(),
      name: "linkedin",
      url: "https://www.linkedin.com/in/danielsnitkovskiy/",
    },
    {
      id: nanoid(),
      name: "github",
      url: "https://github.com/snitkdan",
    },
    {
      id: nanoid(),
      name: "envelope",
      url: "mailto:hello@dan-js.dev",
    },
    {
      id: nanoid(),
      name: "medium",
      url: "https://medium.com/@snitkdan",
    },
  ],
};
